
import { Component, Watch } from 'vue-property-decorator'
import Emitter from '@/utils/emitter'
import { ElForm } from 'element-ui/types/form'
import ZButton from '@/components/base/z-button.vue'
import { isWechat } from '@/utils/utils'
import { formatDate } from '@/utils/date'
@Component({
	components: { ZButton },
	filters: {
		formatDate(value: number) {
			return value ? formatDate('yyyy-MM-dd h:i:s', value) : '-'
		}
	}
})
export default class extends Emitter {
	public $refs!: {
		form: ElForm
	}
	private isWechat = false
	private isLoading = false
	private list: any = []
	private editForm = {
		code: '',
		package_num: ''
	}

	private validateForm = {
		package_num: [
			{
				required: true,
				message: '请输入包裹单号',
				trigger: 'blur'
			}
		],
		code: [
			{
				required: true,
				message: '',
				trigger: 'blur',
				validator: function(rule: any, value: any, callback: Function) {
					if (!value) {
						callback(new Error('请输入商品条码'))
					} else {
						callback()
					}
				}
			}
		]
	}
	async initJSSdk() {
		if (!isWechat()) return false
		const url = location.href.split('#')[0]
		const { data } = await this.$axios.post('v1/jobline/js-sdk-config', {
			url
		})
		this.$wx.config({
			debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
			...data,
			jsApiList: ['scanQRCode'] // 必填，需要使用的JS接口列表
		})
		this.$wx.ready(() => {
			this.isWechat = true
		})
	}
	created() {
		// this.isWechat = isWechat()
		const { package_num = '' } = this.$route.query as { package_num: string }
		this.editForm.package_num = package_num
		this.initJSSdk()
	}

	@Watch('editForm.package_num')
	onPackageNumChange(val: string) {
		if (val && val.startsWith('GLP') && val.length > 12) {
			this.editForm.package_num = val.substr(0, 13)
			this.getBarCodeList()
			return
		}
		this.editForm.package_num = ''
		this.list = []
	}
	async scanBarcode() {
		if (!this.$wx) return false
		this.$wx.scanQRCode({
			needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
			scanType: ['barCode'], // 可以指定扫二维码还是一维码，默认二者都有
			success: (res: any) => {
				const result = res.resultStr.split(',')
				const code = result[result.length - 1]
				if (/^GLP\d+/.test(code)) {
					this.editForm.package_num = code
				} else if (/^\d{8,20}$/.test(code)) {
					this.editForm.code = code
				} else {
					this.$message.error('请扫描正确的条码')
					return
				}
				this.doInput()
			}
		})
	}
	async remove({ row, $index }: any) {
		const isConfirm = await this.$confirm('确认删除？', '提示', {
			confirmButtonText: '确认',
			cancelButtonText: '取消',
			type: 'warning'
		}).catch(() => false)
		if (!isConfirm) return false
		await this.$axios.delete(`v1/jobline/product/bar-code/${row.id}`)
		this.list.splice($index, 1)
	}

	async getBarCodeList() {
		this.isLoading = true
		const { data } = await this.$axios.get(
			`v1/jobline/product/bar-code/${this.editForm.package_num}`
		)
		console.log(data)
		this.list = data
		this.isLoading = false
	}

	async doInput() {
		const isValidate = await this.$refs.form.validate().catch(() => false)
		if (!isValidate) return
		const { code, package_num } = this.editForm
		const { data } = await this.$axios
			.post('v1/jobline/product/bar-code', {
				bar_code: code,
				package_num
			})
			.finally(() => {
				this.isLoading = false
			})
		if (!data) {
			this.$message.error('录入失败！')
			return
		}
		this.$message.success('录入成功')
		this.editForm.code = ''
		this.list.unshift(data)
	}
}
