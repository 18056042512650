var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "z-page",
    [
      _c(
        "el-breadcrumb",
        {
          staticStyle: { "margin-top": "20px" },
          attrs: { "separator-class": "el-icon-arrow-right" },
        },
        [
          _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
            _vm._v("首页"),
          ]),
          _c("el-breadcrumb-item", { attrs: { to: { path: "/warehouse" } } }, [
            _vm._v("仓储"),
          ]),
          _c("el-breadcrumb-item", [_vm._v("录入商品条码")]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c("el-row", { attrs: { gutter: 30, span: 24 } }, [
            _c(
              "div",
              { staticClass: "container info-card" },
              [
                _c(
                  "el-form",
                  {
                    ref: "form",
                    staticClass: "search-form",
                    attrs: {
                      rules: _vm.validateForm,
                      model: _vm.editForm,
                      "label-width": "90px",
                    },
                    nativeOn: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return (() => {}).apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "包裹单号",
                          prop: "package_num",
                          required: "",
                        },
                      },
                      [
                        _c("el-input", {
                          attrs: { clearable: "", placeholder: "包裹单号" },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.doInput.apply(null, arguments)
                            },
                          },
                          model: {
                            value: _vm.editForm.package_num,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.editForm,
                                "package_num",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "editForm.package_num",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "商品条码",
                          prop: "code",
                          required: "",
                        },
                      },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "商品条码", clearable: "" },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.doInput.apply(null, arguments)
                            },
                          },
                          model: {
                            value: _vm.editForm.code,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.editForm,
                                "code",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "editForm.code",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      [
                        _vm.isWechat
                          ? _c(
                              "z-button",
                              {
                                attrs: { type: "default" },
                                on: { click: _vm.scanBarcode },
                              },
                              [_vm._v("扫码")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-table",
                  {
                    staticClass: "ordering-list",
                    staticStyle: { width: "100%" },
                    attrs: { data: _vm.list },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { prop: "bar_code", label: "Info" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("p", [
                                _vm._v(
                                  "商品条码: " + _vm._s(scope.row.bar_code)
                                ),
                              ]),
                              _c("p", [
                                _vm._v(
                                  "录入时间: " +
                                    _vm._s(
                                      _vm._f("formatDate")(scope.row.created)
                                    )
                                ),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: { label: "操作", fixed: "right", width: "100" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "z-button",
                                {
                                  attrs: {
                                    type: "danger",
                                    size: "mini",
                                    plain: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.remove(scope)
                                    },
                                  },
                                },
                                [
                                  _c("i", { staticClass: "el-icon-delete" }),
                                  _vm._v("删除"),
                                ]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }